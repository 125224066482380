import "./home_interno.css";
import Navbar from "../../../components/navbar/navbar.jsx";
import { Link, useNavigate } from "react-router-dom";


function HomeInterno(){


    return <>

        <Navbar Interno />

        
    
    </>
}

export default HomeInterno;